const makeMap = (mapname: string, x: number, y: number): MapData => ({
  sprite: `url(/maps/${mapname})`,
  x,
  y,
})

const makeMapOutlines = (mapname: string, x: number, y: number): MapData => ({
  sprite: `url(/maps/${mapname}), url(/maps/bg.png)`,
  style: {
    'background-blend-mode': 'multiply',
    'background-size': 'cover, 512px',
    'background-repeat': 'no-repeat, repeat',
  },
  x,
  y,
})

export type MapData = { sprite: string; x: number; y: number; style?: Record<string, string> }

export const maps: Record<string, MapData> = {
  'default-30x34': makeMap('default.png', 30, 34),

  // Campaign 3
  'travelling-theater-35x45': makeMap('travelling-theater.jpg', 35, 45),
  'travelling-theater-night-35x45': makeMap('travelling-theater-night.jpg', 35, 45),
  'winding-alleys-night-30x20': makeMap('winding-alleys-night.jpg', 30, 20),
  'overgrown-tomb-dark-20x40': makeMap('overgrown-tomb-dark.jpg', 20, 40),
  'haunted-cabin-night-25x25': makeMap('haunted-cabin-night.jpg', 25, 25),
  'cellar-tunnel-dark-20x20': makeMap('cellar-tunnel-dark.jpg', 20, 20),
  'roadside-clearing-fall-26x26': makeMap('roadside-clearing-fall.jpg', 26, 26),
  'sewer-tunnels-filthy-30x25': makeMap('sewer-tunnels-filthy.jpg', 30, 25),
  'uninfested-tomb-dim-17x25': makeMap('uninfested-tomb-dim.jpg', 17, 25),
  'covenant-council-hall-29x59': makeMap('courtofjustice-purple.jpg', 29, 59),
  'tel-edhil-square-36x59': makeMap('elvencitycenter-moonlight.jpg', 36, 59),
  'sunken-ruins-murky-orb-25x30': makeMap('sunken-ruins-murky-orb.jpg', 25, 30),
  'wilricks-tower-lvl1-23x23': makeMap('arcaneclocktower-lvl0-groundfloor.jpg', 23, 23),
  'wilricks-tower-lvl2-inactive-29x29': makeMap('arcaneclocktower-nonmagical-clockroom-day.jpg', 29, 29),
  'wilricks-tower-lvl2-active-29x29': makeMap('arcaneclocktower-lvl3-clockroom.jpg', 29, 29),
  'fighting-pit-45x35': makeMap('fighting-pit.jpg', 45, 35),
  'manor-basement-dungeon-20x40': makeMap('manor-basement-dungeon.jpg', 20, 40),
  'victoriangreenhouse-eerie-33x57': makeMap('victoriangreenhouse-eerie.jpg', 33, 57),
  'mountainside-temple-sunset-33x25': makeMap('mountainside-temple-sunset.jpg', 33, 25),
  'sinister-bank-33x38': makeMap('sinister-bank.jpg', 33, 38),
  'town-hall-1st-night-20x20': makeMap('town-hall-1st-night.jpg', 20, 20),
  'covenant-city-streets-36x48': makeMap('city-streets.webp', 36, 48),
  'covenant-city-streets-night-36x48': makeMap('city-streets-night.png', 36, 48),
  'forgottenchapel-graveyard-rain-30x44': makeMap('forgottenchapel-graveyard-rain.jpg', 30, 44),
  'forgottenchapel-crypt-forgottenspell-26x34': makeMap('forgottenchapel-crypt-forgottenspell.jpg', 26, 34),
  'forgottenchapel-crypt-original-26x34': makeMap('forgottenchapel-crypt-original.jpg', 26, 34),
  'the-drunken-unicorn-20x30': makeMap('the-drunken-unicorn.jpg', 20, 30),
  'logging-camp-30x20': makeMap('logging-camp.jpg', 30, 20),
  'haunted-mire-altar-murky-40x30': makeMap('haunted-mire-altar-murky.jpg', 40, 30),
  'bansheemoor-bridge-22x40': makeMap('bansheemoor-bridge.jpg', 22, 40),
  'haunted-mire-ruins-night-40x30': makeMap('haunted-mire-ruins-night.jpg', 40, 30),
  'haunted-bridge-35x50': makeMap('haunted-bridge.jpg', 35, 50),
  'mushroom-forest-foggy-30x30': makeMap('mushroom-forest-foggy.jpg', 30, 30),
  'pumpkin-patch-25x30': makeMap('pumpkin-patch.jpg', 25, 30),
  'abandoned-campsite-25x25': makeMap('abandoned-campsite.jpg', 25, 25),
  'fungus-cavern-32x38': makeMap('fungus-cavern.jpeg', 32, 38),
  'haunted-chasm-steam-35x40': makeMap('haunted-chasm-steam.jpg', 35, 40),
  'blackdragonlair-smoke-41x45': makeMap('blackdragonlair-smoke.jpg', 41, 45),
  'darkwoodsedge-fog-42x62': makeMap('darkwoodsedge-fog.jpg', 42, 62),
  'forests-edge-spooky-40x40': makeMap('forests-edge-spooky.jpg', 40, 40),
  'devastated-forest-border-35x35': makeMap('devastated-forest-border.jpg', 35, 35),
  'hiddenwitchshut-fireflies-29x46': makeMap('hiddenwitchshut-fireflies.jpg', 29, 46),
  'rowboat-fishing-30x30': makeMap('rowboat-fishing.webp', 30, 30),
  'ravenroost1-60x60': makeMap('ravenroost1.png', 60, 60),
  'ravenroost2-60x60': makeMap('ravenroost2.png', 60, 60),
  'ravenroost3-60x60': makeMap('ravenroost3.png', 60, 60),
  'haunted-tree-idle-night-40x50': makeMap('haunted-tree-idle-night.jpg', 40, 50),
  'forest-cave-spectral-40x40': makeMap('forest-cave-spectral.jpg', 40, 40),
  'redscarf-keep-snow-day-32x47': makeMap('redscarf-keep-snow-day.jpg', 32, 47),
  'snowy-road-25x25': makeMap('snowy-road.jpg', 25, 25),
  'woodland-glade-snowy-35x35': makeMap('woodland-glade-snowy.jpg', 35, 35),
  'road-ambush-flurry-cleared-30x30': makeMap('road-ambush-flurry-cleared.jpg', 30, 30),
  'sacred-pool-40x45': makeMap('sacred-pool.jpg', 40, 45),
  'bansheemoor-snow-22x40': makeMap('bansheemoor-snow.jpg', 22, 40),
  'burialbarrow-winter-day-32x47': makeMap('burialbarrow-winter-day.jpg', 32, 47),
  'burialbarrow-winter-night-32x47': makeMap('burialbarrow-winter-night.jpg', 32, 47),
  'small-cave-winter-cropped-25x20': makeMap('small-cave-winter-cropped.jpg', 25, 20),
  'split-cavern-dark-40x30': makeMap('split-cavern-dark.jpg', 40, 30),
  'volcanic-walkway-30x32': makeMap('volcanic-walkway.jpg', 30, 32),
  'nymphfountain-original-day-33x57': makeMap('nymphfountain-original-day.jpg', 33, 57),
  'helix-spring-eerie-35x35': makeMap('helix-spring-eerie.jpg', 35, 35),
  'glacialistcavern-íce-35x50': makeMap('cavernofthevenomqueen-frostcave.jpg', 35, 50),
  'glacialistcavern-dark-70x100': makeMap('cavernofthevenomqueen-darkcave.jpg', 70, 100),
  'arcticexpedition-grimdays-45x87': makeMap('arcticexpedition-grimdays.jpg', 45, 87),
  'village-drawbridge-night-raised-35x25': makeMap('village-drawbridge-night-raised.jpg', 35, 25),
  'whitport-ftc-factory-40x30': makeMap('whitport-ftc-factory.png', 40, 30),
  'conquest-hall-exterior-26x40': makeMap('159-winter-lodge-26x40-normal-day.jpg', 26, 40),
  'conquest-hall-basement-26x40': makeMap('160-winter-lodge-interior-26x40-nogrid-basement-day.png', 26, 40),
  'conquest-hall-groundfloor-26x40': makeMap('160-winter-lodge-26x40-normal-day-groundfloor.jpg', 26, 40),
  'conquest-hall-topfloor-26x40': makeMap('160-winter-lodge-26x40-nogrid-normal-day-firstfloor.jpg', 26, 40),
  'manor-prince-groundfloor-40x34': makeMap('manor-prince-groundfloor.jpg', 40, 34),
  'manor-prince-topfloor-40x34': makeMap('manor-prince-topfloor.jpg', 40, 34),
  'snowy-street-30x30': makeMap('snowy-street.jpg', 30, 30),
  'whitport-ftc-dock-44x28': makeMap('whitport-ftc-dock.jpg', 44, 28),
  'steamfactory-original-floor1-40x22': makeMap('steamfactory-original-floor1.jpg', 40, 22),
  'village-shrine-foggy-35x30': makeMap('village-shrine-foggy.jpg', 35, 30),
  'whitport-barricades-50x25': makeMap('whitport-barricades.png', 50, 25),
  'stables-winter-day-27x40': makeMap('128-stables-27x40-winter-day.jpg', 27, 40),

  // Campaign 1 & 2
  'chain-of-islands-30x30': makeMapOutlines('chain-of-islands.jpg', 30, 30),
  'arena-17x13': makeMap('arena.png', 17, 13),
  'lmop-cragmaw-30x21': makeMap('cragmaw.png', 30, 21),
  'lmop-redbrand-28x19': makeMap('redbrand_hideout.png', 28, 19),
  'lmop-cragmawcastle-28x19': makeMap('cragmaw-castle.png', 28, 19),
  'lmop-thundertree-60x40': makeMap('thundertree.png', 60, 40),
  'lmop-waveechocave-73x99': makeMap('wave-echo-cave.png', 73, 99),
  'tembridge-27x21': makeMap('tembridge-cultists.png', 27, 21),
  'the-wavecrester-22x26': makeMap('wavecrester.png', 22, 26),
  'tembridge-safehouse-30x32': makeMap('tembridge-safehouse.png', 30, 32),
  'silversilt-quarry-30x24': makeMap('mine-outside.png', 30, 24),
  'silversilt-tunnels-30x32': makeMap('mine1.png', 30, 32),
  'silversilt-mines-30x32': makeMap('mine2.png', 30, 32),
  'iacaris-workshop-30x34': makeMap('workshop.png', 30, 34),
  'emberfall-camp-29x21': makeMap('emberfall_camp.png', 29, 21),
  'emberfall-village-20x20': makeMap('emberfall_village.png', 20, 20),
  'emberfall-path-30x20': makeMap('emberfall_path.png', 30, 20),
  'emberfall-caldera-25x25': makeMap('emberfall_caldera.png', 25, 25),
  'greystone-warehouse-25x23': makeMap('greystone_warehouse.png', 25, 23),
  'greystone-sewers-22x18': makeMap('greystone-sewer.png', 22, 18),
  'greystone-crimson-hideout-35x35': makeMap('crimson-hideout.png', 35, 35),
  'cottenhorn-bladderball-arena-27x21': makeMap('cottenhorn-arena.png', 27, 21),
  'cottenhorn-rustynail-30x20': makeMap('cottenhorn-rusty-nail.png', 30, 20),
  'rocky-island-props-35x35': makeMap('rocky-island-props.jpg', 35, 35),
  'jungle-camp-night-25x25': makeMap('jungle-camp-night.jpg', 25, 25),
  'magic-shop-2nd-night-18x16': makeMap('magic-shop-2nd-night.jpg', 18, 16),
  'snowy-altar-25x20': makeMap('snowy-altar.jpg', 25, 20),
  'snowy-altar-night-25x20': makeMap('snowy-altar-night.jpg', 25, 20),
  'snowy-winter-ruins-night-30x30': makeMap('snowy-winter-ruins-night.jpg', 30, 30),
  'spooky-swamp-30x30': makeMap('spooky-swamp.jpg', 30, 30),
  'feywild-encampment-30x30': makeMap('feywild-encampment.jpg', 30, 30),
  'forest-bridge-night-30x26': makeMap('forest-bridge-night.jpg', 30, 26),
  'crumbling-fort-36x36': makeMap('crumbling-fort.jpg', 36, 36),
  'crumbling-fort-night-36x36': makeMap('crumbling-fort-night.jpg', 36, 36),
  'dungeon1-30x20': makeMap('dungeon1.png', 30, 20),
  'witchs-hut-night-20x20': makeMap('witchs-hut-night-roofed.jpg', 20, 20),
  'hag-cave-25x20': makeMap('hag-cave.jpg', 25, 20),
  'forest-bridge-30x26': makeMap('forest-bridge.jpg', 30, 26),
  'open-plains-30x30': makeMap('open-plains.jpg', 30, 30),
  'open-plains-night-30x30': makeMap('open-plains-night.jpg', 30, 30),
  'autumn-grave-night-30x24': makeMap('autumn-grave-night.jpg', 30, 24),
  'tomb-30x25': makeMap('tomb.jpg', 30, 25),
  'fall-pond-23x22': makeMap('fall-pond.jpg', 23, 22),
  'desert-bazaar-24x22': makeMap('desert-bazaar.jpg', 24, 22),
  'mountainside-trail-gray-25x20': makeMap('mountainside-trail-gray.jpg', 25, 20),
  'oceanside-ruins-autumn-30x30': makeMap('oceanside-ruins-autumn.jpg', 30, 30),
  'creepy-crypt-25x19': makeMap('creepy-crypt.jpg', 25, 19),
  'ancient-catacombs-29x25': makeMap('ancient-catacombs.jpg', 29, 25),
  'swamp-path-30x28': makeMap('swamp-path.jpg', 30, 28),
  'swamp-ruins-30x30': makeMap('swamp-ruins.jpg', 30, 30),
  'swamp-shrine-25x30': makeMap('swamp-shrine.jpg', 25, 30),
  'swamp-village-30x30': makeMap('swamp-village.jpg', 30, 30),
  'ambar-atador-40x30': makeMap('ambar-atador.jpg', 40, 30),
  'overgrown-caves-24x33': makeMap('overgrown-caves.jpg', 24, 33),
  'frozen-fountain-30x30': makeMap('frozen-fountain.jpg', 30, 30),
  'mountain-transition-24x34': makeMap('mountain-transition.jpg', 24, 34),
  'snowy-forest-outcrop-25x25': makeMap('snowy-forest-outcrop.jpg', 25, 25),
  'snowy-waterfall-30x30': makeMap('snowy-waterfall.jpg', 30, 30),
  'snowy-winter-ruins-30x30': makeMap('snowy-winter-ruins.jpg', 30, 30),
  'frozen-clearing-30x30': makeMap('frozen-clearing.jpg', 30, 30),
  'sailing-ship-24x35': makeMap('sailing-ship.jpg', 24, 35),
  'sailing-ship-foggy-24x35': makeMap('sailing-ship-foggy.jpg', 24, 35),
  'sailing-ship-night-24x35': makeMap('sailing-ship-night.jpg', 24, 35),
  'jungle-ledge-30x30': makeMap('jungle-ledge.jpg', 30, 30),
  'sailing-ship-battle-24x19': makeMap('ship-battle.jpg', 24, 19),
  'frozen-ascent-25x25': makeMap('frozen-ascent.jpg', 25, 25),
  'frozen-shrine-24x24': makeMap('desert-shrine-winter.jpg', 24, 24),
  'rocky-forest-autumn-25x25': makeMap('rocky-forest-autumn.jpg', 25, 25),
  'mead-hall-28x18': makeMap('mead-hall.jpg', 28, 18),
  'shipwreck-25x20': makeMap('shipwreck.jpg', 25, 20),
  'twisted-shrine-25x25': makeMap('twisted-shrine.jpg', 25, 25),
  'branching-caves-dark-24x24': makeMap('branching-caves-dark.jpg', 24, 24),
  'feywild-clearing-grim-25x25': makeMap('feywild-clearing-grim.jpg', 25, 25),
  'green-dragon-lair-spectral-35x30': makeMap('green-dragon-lair-spectral.jpg', 35, 30),
  'ship-battle-large-32x18': makeMap('ship-battle-large.jpg', 32, 18),
  'fall-forested-canyon-night-28x40': makeMap('fall-forested-canyon-night.jpg', 28, 40),
  'autumn-boneyard-night-25x25': makeMap('autumn-boneyard-night.jpg', 25, 25),
  'autumn-woods-night-28x30': makeMap('autumn-woods-night.jpg', 28, 30),
  'big-island-stormy-40x40': makeMap('big-island-stormy.jpg', 40, 40),
  'druid-circle-1-40x40': makeMap('druid-circle-1.jpg', 40, 40),
  'druid-circle-2-40x40': makeMap('druid-circle-2.jpg', 40, 40),
  'druid-circle-3-40x40': makeMap('druid-circle-3.jpg', 40, 40),
  'druid-circle-4-40x40': makeMap('druid-circle-4.jpg', 40, 40),
  'druid-circle-5-40x40': makeMap('druid-circle-5.jpg', 40, 40),
  'druid-circle-6-40x40': makeMap('druid-circle-6.jpg', 40, 40),
  'rocky-split-cave-22x31': makeMap('rocky-split-cave.jpg', 22, 31),
  'zaphtars-cave-40x40': makeMap('zaphtars-cave.jpg', 40, 40),
  'village-square-stormy-30x30': makeMap('village-square-stormy.jpg', 30, 30),
  'rocky-pass-autumn-night-30x20': makeMap('rocky-pass-autumn-night.jpg', 30, 20),
  'rocky-pass-autumn-30x20': makeMap('rocky-pass-autumn.jpg', 30, 20),
  'villa-ground-40x40': makeMap('villa-ground.jpg', 40, 40),
  'villa-topfloor-40x40': makeMap('villa-topfloor.jpg', 40, 40),
  'villa-dungeons-40x40': makeMap('villa-dungeons.jpg', 40, 40),
  'desert-canyon-night-35x35': makeMap('desert-canyon-night.jpg', 35, 35),
  'open-dunes-night-30x30': makeMap('open-dunes-night.jpg', 30, 30),
  'open-dunes-30x30': makeMap('open-dunes.jpg', 30, 30),
  'fall-logging-camp-night-29x21': makeMap('fall-logging-camp-night.jpg', 29, 21),
  'dreary-forest-stream-day-24x27': makeMap('dreary-forest-stream-day.jpg', 24, 27),
  'dreary-forest-stream-night-24x27': makeMap('dreary-forest-stream-night.jpg', 24, 27),
  'troll-cave-30x30': makeMap('troll-cave.png', 30, 30),
  'arastaslair-80x31': makeMap('arastaslair.png', 80, 31.28),
  'mountain-pass-night-28x28': makeMap('mountain-pass-night.jpg', 28, 28),
  'underground-lake-dark-35x35': makeMap('underground-lake-dark.jpg', 35, 35),
  'underdark-ruins-30x30': makeMap('underdark-ruins.jpg', 30, 30),
  'underdark-wilds-28x28': makeMap('underdark-wilds.jpg', 28, 28),
  'underdark-wilds-dark-28x28': makeMap('underdark-wilds-dark.jpg', 28, 28),
  'underdark-waterfall-25x30': makeMap('underdark-waterfall.jpg', 25, 30),
  'cave-village-dark-25x25': makeMap('cave-village-dark.jpg', 25, 25),
  'barren-passage-dark-30x30': makeMap('barren-passage-dark.jpg', 30, 30),
  'mushroom-forest-night-30x30': makeMap('mushroom-forest-night.jpg', 30, 30),
  'swamp-cave-25x25': makeMap('swamp-cave.jpg', 25, 25),
  'mountainside-temple-night-33x25': makeMap('mountainside-temple-night.jpg', 33, 25),
  'mountainside-temple-day-33x25': makeMap('mountainside-temple-day.jpg', 33, 25),
  'parkside-market-25x30': makeMap('parkside-market.jpg', 25, 30),
  'roadside-inn-1st-24x24': makeMap('roadside-inn-1st.jpg', 24, 24),
  'roadside-inn-2nd-24x24': makeMap('roadside-inn-2nd.jpg', 24, 24),
  'roadside-inn-1st-fall-24x24': makeMap('roadside-inn-1st-fall.jpg', 24, 24),
  'roadside-inn-2nd-fall-24x24': makeMap('roadside-inn-2nd-fall.jpg', 24, 24),
  'roadside-inn-1st-fall-night-24x24': makeMap('roadside-inn-1st-fall-night.jpg', 24, 24),
  'roadside-inn-2nd-fall-night-24x24': makeMap('roadside-inn-2nd-fall-night.jpg', 24, 24),
  'small-farm-20x25': makeMap('small-farm.jpg', 20, 25),
  'small-farm-roofed-20x25': makeMap('small-farm-roofed.jpg', 20, 25),
  'small-farm-night-20x25': makeMap('small-farm-night.jpg', 20, 25),
  'small-farm-night-roofed-20x25': makeMap('small-farm-night-roofed.jpg', 20, 25),
  'small-farm-fall-20x25': makeMap('small-farm-fall.jpg', 20, 25),
  'small-farm-fall-roofed-20x25': makeMap('small-farm-fall-roofed.jpg', 20, 25),
  'small-farm-fall-night-20x25': makeMap('small-farm-fall-night.jpg', 20, 25),
  'small-farm-fall-night-roofed-20x25': makeMap('small-farm-fall-night-roofed.jpg', 20, 25),
  'volcanic-ruins-32x32': makeMap('volcanic-ruins.jpg', 32, 32),
  'sinister-temple-33x38': makeMap('sinister-temple.jpg', 33, 38),
  'skull-cavern-25x35': makeMap('skull-cavern.jpg', 25, 35),
  'mages-observatory-2nd-dark-25x25': makeMap('mages-observatory-2nd-dark.jpg', 25, 25),
  'volcanic-dungeon-30x30': makeMap('volcanic-dungeon.jpg', 30, 30),
  'frozen-pond-25x25': makeMap('frozen-pond.jpg', 25, 25),
  'frozen-pond-broken-25x25': makeMap('frozen-pond-broken.jpg', 25, 25),
  'sea-cliffs-night-25x30': makeMap('sea-cliffs-night.jpg', 25, 30),
  'mountain-bridge-night-35x35': makeMap('mountain-bridge-night.jpg', 35, 35),
  'shady-brewery-24x35': makeMap('shady-brewery.jpg', 24, 35),
  'garden-temple-lit-22x32': makeMap('garden-temple-lit.jpg', 22, 32),
  'ruined-village-center-night-30x30': makeMap('ruined-village-center-night.jpg', 30, 30),
  'callatis-square-30x40': makeMap('callatis-square.jpeg', 30, 40),
  'callatis-temple-35x50': makeMap('greek-temple.jpg', 35, 50),
  'autumn-city-gates-30x25': makeMap('autumn-city-gates.jpg', 30, 25),
  'lava-cave-1-34x33': makeMap('lava-cave-1.jpg', 34, 33),
  'lava-cave-2-34x33': makeMap('lava-cave-2.jpg', 34, 33),
  'lava-cave-3-34x33': makeMap('lava-cave-3.jpg', 34, 33),
  'lava-cave-4-34x33': makeMap('lava-cave-4.jpg', 34, 33),
  'lava-cave-5-34x33': makeMap('lava-cave-5.jpg', 34, 33),
  'lava-cave-6-34x33': makeMap('lava-cave-6.jpg', 34, 33),
  'lava-cave-7-34x33': makeMap('lava-cave-7.jpg', 34, 33),
  'lava-cave-8-34x33': makeMap('lava-cave-8.jpg', 34, 33),

  // Oneshots
  'partymansion-40x40': makeMap('partymansion.png', 40, 40),
  'manor-beckett-40x40': makeMap('manor-beckett.png', 40, 40),
  'kessigpathleft-40x20': makeMap('kessigpathleft.jpg', 40, 20),
  'kessigm2-24x30': makeMap('kessigm2.jpg', 24, 30),
  'kessigpathright-22x33': makeMap('kessigpathright.jpg', 22, 33),
  'portals1-16x16': makeMap('portals1.jpg', 16, 16),
  'portals2-16x16': makeMap('portals2.jpg', 16, 16),
  'oneshot-mines-levelone-20x13': makeMap('oneshot-mines-levelone.jpg', 20, 13),
  'oneshot-mines-leveltwo-20x13': makeMap('oneshot-mines-leveltwo.jpg', 20, 13),
  'oneshot-mines-levelthree-20x13': makeMap('oneshot-mines-levelthree.jpg', 20, 13),
  'oneshot-mines-levelfour-20x13': makeMap('oneshot-mines-levelfour.jpg', 20, 13),
}

export const fxOptions = {
  darkness: '#000',
  fire: '#c84a40',
  sparkles: '#888',
  digital: 'rgb(164, 203, 215)',
  acid: 'rgb(125, 193, 94)',
  leaves: '#e27e29',
  'area red': 'var(--secondary)',
  'area blue': 'var(--primary)',
  'area green': 'green',
  night: 'rgba(76, 77, 162, .5)',
  light: 'rgb(238, 220, 123)',
}

export const bgOptions = {
  '#fff': '',
  '#888': '',
  '#333': '',
  '#6595ed': '',
  '#3E44B0': '',
  '#41A179': '',
  '#298425': '',
  '#d9e35a': '',
  '#e27e29': '',
  '#c84a40': '',
  '#e57ab7': '',
  '#8441a1': '',
  'url(/sprites/humanoid.jpg)': 'Humanoid',
  'url(/sprites/dragon.jpg)': 'Dragon',
  'url(/sprites/undead.jpg)': 'Undead',
  'url(/sprites/beast.jpg)': 'Beast',
  'url(/sprites/monstrosity.jpg)': 'Monstrosity',
  'url(/sprites/aberration.jpg)': 'Aberration',
  'url(/sprites/construct.jpg)': 'Construct',
  'url(/sprites/celestial.jpg)': 'Celestial',
  'url(/sprites/fiend.jpg)': 'Fiend',
  'url(/sprites/fey.jpg)': 'Fey',
  'url(/sprites/giant.jpg)': 'Giant',
  'url(/sprites/ooze.jpg)': 'Ooze',
  'url(/sprites/plant.jpg)': 'Plant',
  'url(/sprites/elemental.jpg)': 'Elemental',
  'url(/sprites/helge.png)': 'Helge',
  'url(/sprites/iris.png)': 'Iris',
  'url(/sprites/konrad.png)': 'Konrad',
  'url(/sprites/tranvol.png)': 'Tranvol',
  'url(/sprites/urbanus.png)': 'Urbanus',
  'url(/sprites/thalia.png)': 'Thalia',
  'url(/sprites/lumiel.png)': 'Lumiel',
  'url(/sprites/elena.png)': 'Elena',
  'url(/sprites/luthanna.png)': 'Luthanna',
  'url(/sprites/arhitael.png)': 'Arhitael',
  'url(/sprites/aurora.png)': 'Aurora',
  'url(/sprites/frank.png)': 'Frank',
  'url(/sprites/raphael.png)': 'Raphaël',
  'url(/sprites/flashheart.png)': 'Lord Flashheart',
  'url(/sprites/thundin.png)': 'Thundin',
  'url(/sprites/lyra.png)': 'Lyra',
  'url(/sprites/lucy.jpg)': 'Lucy',
  'url(/sprites/sigismund.jpg)': 'Sigismund',
  'url(/sprites/victoria.jpg)': 'Victoria',
  'url(/sprites/gallias.jpg)': 'Gallias',
  'url(/sprites/sophia.png)': 'Sophia',
}

export default {}
